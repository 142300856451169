<template>
  <div>
    <!-- 只有一个题的情况 -->
    <div class="card"
         v-if="form.if_is_head == 0">
      <div class="card_head">
        题目编号：{{form.recordQuestion.question_id}} 题目分数：{{form.recordQuestion.question_score}}
      </div>
      <div class="box-card">
        <div class="clearfix">
          <span>题目内容：</span>
        </div>
        <div>
          <div v-if="form.recordQuestion.question_head_content !== ''"
               v-html="$replaceAll(form.recordQuestion.question_head_content)">

          </div>
          <div v-html="$replaceAll(form.recordQuestion.question_content)"></div>
        </div>
      </div>
      <div class="box-card">
        <div class="clearfix">
          <span>题目答案：</span>
        </div>

        <div style="color: #ff725b;"
             v-html="$replaceAll(form.recordQuestion.question_answer)">

        </div>
      </div>
      <div class="box-card">
        <div class="clearfix">
          <span>题目解释：</span>
        </div>
        <div style="word-wrap: break-word;"
             v-html="$replaceAll(form.recordQuestion.question_explain)">

        </div>
      </div>
    </div>
    <!-- 收藏大题的情况，循环小题 -->
    <div v-else
         class="card">
      <div class="box-card">
        <div class="clearfix">
          <span>题目：</span>
        </div>
        <div>
          <div v-if="form.recordQuestion"
               v-html="$replaceAll(form.recordQuestion.question_content)"></div>
        </div>
      </div>
      <div class="card"
           v-for="(item,index) in form.list"
           :key="index">
        <div class="card_head">
          题目编号：{{item.question_id}} 题目分数：{{item.question_score}}
        </div>
        <div class="box-card">
          <div class="clearfix">
            <span>题目内容：</span>
          </div>
          <div>
            <div v-html="$replaceAll(item.question_content)"></div>
          </div>
        </div>
        <div class="box-card">
          <div class="clearfix">
            <span>题目答案：</span>
          </div>
          <div style="color: #ff725b;"
               v-html="$replaceAll(item.question_answer)">
          </div>
        </div>
        <div class="box-card">
          <div class="clearfix">
            <span>题目解释：</span>
          </div>
          <div style="word-wrap: break-word;"
               v-html="$replaceAll(item.question_explain)">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      form: [],
      a: 5,
      id: ''
    }
  },
  mounted () {
    this.id = this.$route.query.id
    this.getInfo()
  },
  methods: {
    getInfo () {
      this.$http({
        url: '/api/v1/question/discard_show',
        method: 'get',
        params: {
          question_id: this.id
        }
      }).then(res => {
        this.form = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  margin: auto;
  padding-top: 10px;
  font-size: 16px;
  padding-bottom: 20px;
  .card_head {
    display: flex;
    font-size: 16px;
    font-weight: bold;
    margin-left: 15px;
    background: white;
    span {
      flex: 1;
    }
  }
}
.el-card {
  padding: 0 15px 15px 15px;
}
.box-card {
  margin-top: 10px;
  .clearfix {
    margin-bottom: 20px;
    border-left: 4px solid #37a0f4;
    text-indent: 1em;
    font-size: 16px;
    font-weight: bold;
  }
  .topic_content {
    margin-left: 20px;
    font-size: 16px;
  }
}
</style>